import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import FloatingVue from 'floating-vue'
import { createHead } from 'unhead'
createHead()

import 'floating-vue/dist/style.css'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Clair'
const appEnv = import.meta.env.VITE_APP_ENV
const appUrl = import.meta.env.VITE_APP_URL
const sentryDsn = import.meta.env.VITE_SENTRY_LARAVEL_DSN

import.meta.glob(['../images/**', '../fonts/**'])

createInertiaApp({
  progress: { color: '#09459C' },
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const vueApp = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(FloatingVue)
      .provide('calendlyUrl', import.meta.env.VITE_CALENDLY_URL)

    if (appEnv !== 'local') {
      setupSentry(vueApp)
    }

    vueApp.mount(el)

    return vueApp
  },
})

function setupSentry(app) {
  if (sentryDsn) {
    Sentry.init({
      app,
      dsn: sentryDsn,
      environment: appEnv,
      integrations: [
        new BrowserTracing({
          // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', appUrl, /^\//],
        }),
      ],
      tracesSampleRate: 0.2,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    })
  }
}
